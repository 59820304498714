/* Ocultar la barra de scroll vertical en todos los navegadores */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  @apply hidden-scrollbar;
}

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: transparent;
}